import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from '@zep/utils';
import S from './Dialog.module.scss';

/**
 * @param props.preLine `default: false` 스페이스 및 탭이나 줄 끝의 공백을 제거하고, `\n` 개행으로 자동 줄바꿈 처리를 합니다.
 */
export const DialogContent = forwardRef<HTMLDivElement, PropsWithChildren<DialogContentProps>>((props, ref) => {
  const {
    className,
    preLine = false,
    children,
    ...rest
  } = props;
  return <div ref={ref} className={cn(S.content, {
    [S.pre_line]: preLine
  }, className)} {...rest}>
      {children}
    </div>;
});
DialogContent.displayName = 'DialogContent';
type DialogContentProps = {
  className?: string;
  preLine?: boolean;
} & Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'id'>;