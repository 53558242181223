import { DialogTitle as _DialogTitle, DialogTitleProps } from '@headlessui/react';
import { cn } from '@zep/utils';
import S from './Dialog.module.scss';
export const DialogTitle = (props: DialogTitleProps<'div'>) => {
  const {
    className,
    children
  } = props;
  return <_DialogTitle {...props} className={cn(S.title, 'text-heading-md-bold whitespace-pre-line', className)} data-sentry-element="_DialogTitle" data-sentry-component="DialogTitle" data-sentry-source-file="DialogTitle.tsx">
      {children}
    </_DialogTitle>;
};