import { useTranslation } from 'next-i18next';
import { useAlertDialog } from './useAlertDialog';
export function useUnknownErrorDialog() {
  const {
    t
  } = useTranslation(['common']);
  const alertDialog = useAlertDialog();
  const open = async () => {
    return await alertDialog.open({
      title: t(
      // TODO: shared로 사용되는 코드이므로 common.unknownErrorDialog.title 로 변경 필요. 크게 문제가 되는 건 아니라서 일단 둡니다.
      'googleClassroom.unknownErrorDialog.title', '알 수 없는 오류가 발생했습니다'),
      content: t('googleClassroom.unknownErrorDialog.content', '다시 시도해주세요. 문제가 반복되면 ZEP 채널톡으로 문의해주세요.')
    });
  };
  return {
    open,
    didOpen: alertDialog.didOpen
  };
}