import { PropsWithChildren, SyntheticEvent } from 'react';
import { IconContainer, XIcon } from '@zep/icons';
import { ICON_COLOR } from '@zep/ui/ui-tokens/color';
import { cn } from '@zep/utils';
import S from './Dialog.module.scss';
export const DialogHeader = (props: PropsWithChildren<DialogHeaderProps>) => {
  const {
    className,
    topSlot,
    leftSlot,
    rightSlot,
    onClose,
    children,
    onCloseIcon,
    closeButtonTabIndex = 0
  } = props;
  const handleClose = (e: SyntheticEvent) => {
    onClose && onClose(e);
  };
  return <header className={cn(S.header, className)} data-sentry-component="DialogHeader" data-sentry-source-file="DialogHeader.tsx">
      {topSlot && <span className={cn(S.col, S.topSlot)}>{topSlot}</span>}
      <div className={S.col}>
        {leftSlot && <div className={cn(S.slot, S.leftSlot)}>{leftSlot}</div>}
        <div className={cn(S.slot, S.title)}>{children}</div>
        {(rightSlot || onClose) && <div className={cn(S.slot, S.rightSlot)}>
            {rightSlot && rightSlot}
            {onClose && <IconContainer type="button" onClick={handleClose} className={S.close} tabIndex={closeButtonTabIndex}>
                {onCloseIcon ? onCloseIcon : <XIcon width={24} height={24} fill={ICON_COLOR.default} aria-label="Close" />}
              </IconContainer>}
          </div>}
      </div>
    </header>;
};
type DialogHeaderProps = {
  topSlot?: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  className?: string;
  onClose?: (e: SyntheticEvent) => void;
  onCloseIcon?: React.ReactNode;
  closeButtonTabIndex?: number;
};