import { getUserAuthInfo, GetUserAuthInfoResponse } from '@zep/api';
import { useUser } from '@zep/lib/auth';
import { ApiTokenStorage } from '@zep/module/auth';
import { useUnknownErrorDialog } from '@zep/shared/hooks';
import { UserType } from '@zep/types';
import { combinePathAndQuery } from '@zep/utils';
import { useRouter } from 'next/router';

export const useOnSuccessLogin = () => {
  const router = useRouter();
  const unknownErrorDialog = useUnknownErrorDialog();
  const { setUser } = useUser();

  return async ({
    zepServerToken,
    callbackUrl,
    userInfo: _userInfo,
  }: {
    zepServerToken: string;
    callbackUrl: string;
    userInfo?: GetUserAuthInfoResponse;
  }) => {
    try {
      ApiTokenStorage.set(zepServerToken);
      const userInfo = _userInfo ?? (await getUserAuthInfo()).data!;
      setUser({
        token: zepServerToken,
        type: userInfo.memberType as UserType,
        admin: userInfo.isAdmin,
        email: userInfo.email ?? '',
        username: userInfo.userName,
        expiredAt: userInfo.expiredAt,
        registrationStatus: userInfo.registrationStatus,
        planType: userInfo.planType,
      });

      await router.replace(
        !userInfo.registrationStatus
          ? combinePathAndQuery('/sign-up/type', router.query)
          : callbackUrl,
      );
    } catch (error) {
      await unknownErrorDialog.open();
      router.replace({ pathname: '/login', query: { callbackUrl } });
      return;
    }
  };
};
