import { Children, CSSProperties, PropsWithChildren } from 'react';
import cns from 'classnames';
import S from './Dialog.module.scss';

/**
 * @description Dialog.Footer 컴포넌트
 * @example
 * ```tsx
 * <Dialog>
 *   <Dialog.Footer>여기에 코드를 추가하세요.</Dialog.Footer>
 * </Dialog>
 * ```
 */
export const DialogFooter = (props: PropsWithChildren<DialogFooterProps>) => {
  const {
    className,
    children
  } = props;

  /**
   * @description children의 개수가 2개 이상일 경우, 버튼이 좌우 배치에서 상하로 배치 됩니다.
   */
  const childrenCount = Children.count(children);
  return <div style={({
    '--after-background': props.background || 'linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #fff 24%)'
  } as CSSProperties)} className={cns(S.footer, {
    [S.vertical]: childrenCount > 2
  }, className)} data-sentry-component="DialogFooter" data-sentry-source-file="DialogFooter.tsx">
      {children}
    </div>;
};
type DialogFooterProps = {
  className?: string;
  // 임의로 footer 에 스타일 주기 위함
  background?: string;
};